import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { IManagerMentorsFilter } from '@/store/types/tables'
import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  ManagerMentorsGetRequest,
  ManagerMentorsMentorIdGetRequest, ManagerMentorsSeniorsGetRequest, ManagerMentorsSeniorsPostRequest,
  MentorLargeResource,
  MentorShortResourcePaginator, SeniorShortResource, SeniorStoreRequest,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с наставниками системы (пользователи из группы прав доступа Наставники)
 * - получение списка
 * - просмотр и редактирование
 */

@Module({
  dynamic: true,
  name: 'ManagerMentors',
  namespaced: true,
  store,
})
class ManagerMentors extends VuexModule {
  // ---------------------------- Mentors ---------------------------- >>
  // Filter
  mentorsFilter: IManagerMentorsFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setMentorsFilter(payload: IManagerMentorsFilter) {
    this.mentorsFilter = Object.assign({}, payload)
  }

  // Entities
  mentors: MentorShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setMentors (payload: MentorShortResourcePaginator) {
    this.mentors = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchMentors () {
    const { data } = await ManagerMentorsGetRequest(tableOptionsToParams(this.mentorsFilter))
    this.setMentors(data)
    return data
  }

  // ---------------------------- Mentor ---------------------------- >>
  mentor: MentorLargeResource | null = null

  @Mutation
  setMentor (payload: MentorLargeResource | null) {
    if (payload) {
      this.mentor = Object.assign({}, payload)
    } else {
      this.mentor = payload
    }
  }

  @Action({ rawError: true })
  async fetchMentor (mentorID: number) {
    const { data } = await ManagerMentorsMentorIdGetRequest(mentorID)
    this.setMentor(data)
    return data
  }

  // ---------------------------- Seniors ---------------------------- >>
  seniors: SeniorShortResource[] = []

  @Mutation
  setSeniors (payload: SeniorShortResource[]) {
    this.seniors = [...payload]
  }

  @Action({ rawError: true })
  async fetchSeniors () {
    const { data } = await ManagerMentorsSeniorsGetRequest()
    this.setSeniors(data)
    return data
  }

  @Action({ rawError: true })
  async saveSenior (params: SeniorStoreRequest) {
    const { data } = await ManagerMentorsSeniorsPostRequest(params)
    this.setSeniors(data)
    return data
  }
}

const ManagerMentorsModule = getModule(ManagerMentors)

export default ManagerMentorsModule
