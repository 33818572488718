



















































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import AllocateMentorsModal from '@/components/modals/mentors/AllocateMentorsModal.vue'
import MentorCard from '@/components/cards/MentorCard.vue'
import PaginationWrapper from '@/components/_uikit/PaginationWrapper.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerMentorsModule from '@/store/modules/manager/mentors'
import {
  IManagerMentorsFilter,
  IManagerMentorsFilterType,
} from '@/store/types'
import { openNewTab } from '@/utils/functions'

enum MenuActions {
  EXCEL = 'excel',
  ALLOCATE = 'allcoate',
}

@Component({
  components: {
    AllocateMentorsModal,
    MentorCard,
    PaginationWrapper,
    Parameters,
    TextInput,
  },
})
export default class MentorsTable extends Mixins(MasterGroupMixin, PermissionsMixin) {
  private showAllocateModal = false

  private get filter () {
    return ManagerMentorsModule.mentorsFilter
  }

  private set filter (filter: IManagerMentorsFilter) {
    ManagerMentorsModule.setMentorsFilter(filter)
    this.fetchMentors()
  }

  private get filterString () {
    return JSON.stringify(this.filter)
  }

  private handleFilter (field: IManagerMentorsFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private get mentors () {
    return ManagerMentorsModule.mentors.data
  }

  private get pagination () {
    return ManagerMentorsModule.mentors.meta
  }

  private get menu() {
    const list = []

    list.push({ name: 'Выгрузка в Excel', value: MenuActions.EXCEL })
    list.push({ name: 'Распределение ', value: MenuActions.ALLOCATE })

    return list
  }

  private mounted () {
    this.fetchMentors()
  }

  @Bind
  @Debounce(300)
  private fetchMentors () {
    ManagerMentorsModule.fetchMentors()
      .catch(this.notifyError)
  }

  private handleExport () {
    openNewTab('/manager/mentors/export', this.filter)
  }

  private handleMenuItemClick(action: MenuActions) {
    switch (action) {
    case MenuActions.EXCEL:
      this.handleExport()
      break
    case MenuActions.ALLOCATE:
      this.showAllocateModal = true
      break
    }
  }

  // @Watch('filterString')
  // private watchFilter (value: string) {
  //   this.filter = JSON.parse(value)
  //   // ManagerMentorsModule.setMentorsFilter(this.filter)
  // }
}
