








import { Vue, Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// components
import MentorsEducationTable from '@/components/tables/MentorsTable.vue'

@Component({
  components: {
    MentorsEducationTable,
  },
})
export default class MentorsList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Наставники',
    }
  }
}
