
































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import SeniorCard from '@/components/cards/SeniorCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerMentorsModule from '@/store/modules/manager/mentors'

@Component({
  components: {
    SeniorCard,
  },
})
export default class AllocateMentorsModal extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private visible!: boolean

  private get seniors () {
    return ManagerMentorsModule.seniors
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private mounted () {
    if (this.seniors.length === 0) {
      this.fetchSeniors()
    }
  }

  @Bind
  @Debounce(300)
  private fetchSeniors () {
    ManagerMentorsModule.fetchSeniors()
      .catch(this.notifyError)
  }

  private handleClose () {
    this.$emit('update:visible', false)
  }
}
