



















import { Component, Mixins, Prop } from 'vue-property-decorator'

import ChartExercise from '@/components/charts/ChartExercise.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import UserCard from '@/components/cards/UserCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MentorShortResource, RoleType } from '@/store/types'

@Component({
  components: {
    ChartExercise,
    UserCard,
    UserSocialCard,
  },
})
export default class MentorCard extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private mentor!: MentorShortResource

  @Prop({ required: true })
  private groupId!: number

  private get showCharts () {
    return this.mentor.roleTypes.includes(RoleType.MENTOR)
  }
}
