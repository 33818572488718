
































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import UserCard from '@/components/cards/UserCard.vue'
import UsersMultipleSearch from '@/components/_uikit/search/UsersMultipleSearch.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerMentorsModule from '@/store/modules/manager/mentors'
import { RoleType, SeniorShortResource, SeniorStoreRequest, UserShortResource } from '@/store/types'

@Component({
  components: {
    UserCard,
    UsersMultipleSearch,
  },
})
export default class SeniorCard extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private senior!: SeniorShortResource

  private isFormVisible = false
  private form: SeniorStoreRequest = {
    mentorIds: [],
    seniorId: 0,
  }

  private get roleTypes () {
    return [RoleType.MENTOR, RoleType.SPECIAL_MENTOR]
  }

  private showForm () {
    this.form = {
      mentorIds: this.senior.mentors.map((mentor: UserShortResource) => mentor.id),
      seniorId: this.senior.id,
    }
    this.isFormVisible = true
  }

  private hideForm () {
    this.form = {
      mentorIds: [],
      seniorId: 0,
    }
    this.isFormVisible = false
  }

  private handleSelectMentors (users: UserShortResource[]) {
    this.form.mentorIds = users.map((user: UserShortResource) => user.id)
  }

  @Bind
  @Debounce(300)
  private handleSubmit () {
    ManagerMentorsModule.saveSenior(this.form)
      .then(() => {
        this.notifySuccess('Данные сохранены')
        this.hideForm()
      })
      .catch(this.notifyError)
  }
}
